import React from 'react';
import styled from 'styled-components';
import AnimatedLink from '../../common/AnimatedLink';
import getSlug from '../../utils/getSlug';

const StyledView = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	padding: 40px 80px;
	position: relative;
	text-align: center;
	width: 100%;
	@media (min-width: 768px) and (max-width: 1024px) {
		padding: 32px 64px;
	}
	@media (max-width: 767px) {
		padding: 12px 24px;
	}
`;
const StyledHeader = styled.h2`
	font-family: ${({ theme, isUA }) => (isUA ? theme.fontFamily.primary : theme.fontFamily.primaryItalic)};
	font-size: 32px;
	font-weight: ${({ theme, isUA }) => (isUA ? theme.fontWeight.medium : theme.fontWeight.bold)};
	line-height: 1.6;
	margin-bottom: 47px;
	max-width: 782px;
	opacity: 0;
	overflow: hidden;
	top: 50px;
	width: 100%;
	@media (max-width: 768px) {
		font-size: 24px;
	}
`;

const TextModule = ({ data, activeLang }) => {
	const { textLink, textLinkSlug, textDescription } = data;
	const description = textDescription || '';
	const title = textLink || '';
	const link = textLinkSlug?.[0]?.slug || '';
	const isUA = activeLang === 'ua';

	return (
		<StyledView className="section">
			<StyledHeader className="text-desc anim" data-animation-type="text-desc" isUA={isUA}>
				{description}
			</StyledHeader>
			<AnimatedLink to={`/${getSlug(link, activeLang)}`} isDarkBg={false} title={title} className="btn" isUA={isUA} />
		</StyledView>
	);
};

export default TextModule;
