import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MouseParallaxChild, MouseParallaxContainer } from 'react-parallax-mouse';
import ScrollIndicator from '../ScrollIndicator/ScrollIndicator';
import GetHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const StyledMainPhoto = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	height: 100vh;
	object-fit: contain;
	overflow: hidden;
	padding: 0 90px 71px 80px;
	width: 100%;
	@media (min-width: 768px) and (max-width: 1024px) {
		padding: 0 64px 50px;
		height: 75vh;
	}
	@media (max-width: 767px) {
		padding: 0 25px 50px;
		height: 65vh;
	}

	.gatsby-image-wrapper,
	.main-img {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}
`;
const StyledDesc = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	height: calc(var(--vh, 1vh) * 100);
	height: 100vh;
	justify-content: center;
	padding: 40px 80px;
	position: relative;
	text-align: center;
	@media (min-width: 768px) and (max-width: 1024px) {
		margin: 32px 64px;
	}
	@media (max-width: 767px) {
		margin: 12px 24px;
		height: calc(var(--vh, 1vh) * 85);
	}
`;
const StyledText = styled.span`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme, isUA }) => (isUA ? theme.fontFamily.primary : theme.fontFamily.primaryItalic)};
	font-size: 32px;
	font-weight: ${({ theme, isUA }) => (isUA ? theme.fontWeight.medium : theme.fontWeight.bold)};
	line-height: 50px;
	max-width: 68%;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	visibility: hidden;
	@media (min-width: 768px) and (max-width: 1024px) {
		font-size: 32px;
		line-height: 50px;
		max-width: 100%;
	}
	@media (min-width: 376px) and (max-width: 767px) {
		font-size: 24px;
		line-height: 36px;
		max-width: 100%;
	}
	@media (max-width: 375px) {
		font-size: 21px;
		line-height: 30px;
		max-width: 100%;
	}
`;
const StyledImgWrapper = styled.div`
	height: 100%;
	position: relative;
	overflow: hidden;
	width: 60%;
	@media (max-width: 1024px) {
		width: 100%;
	}
	.gatsby-image-wrapper {
		height: 100%;
	}
	.reveal-right {
		height: 100%;
		width: 100%;
		background-color: ${({ theme }) => theme.colors.black};
		position: absolute;
		top: 0;
		right: 0;
		z-index: 9;
	}
	img {
		//height: 928px;
		//width: 793px;
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		//@media (min-width: 768px) and (max-width: 1024px) {
		//	height: 76vh;
		//	width: 87.333vw;
		//}
		@media (max-width: 767px) {
			width: 92.933vw;
			height: 63.46vh;
		}
	}
`;
const StyledImgWrapperMain = styled.div`
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;

	.reveal {
		background-color: ${({ theme }) => theme.colors.white};
		height: 100%;
		position: relative;
		width: 101%;
	}
`;
const StyledDescPhoto = styled.div`
	color: ${({ theme }) => theme.colors.white};
	font-family: ${({ theme, isUA }) => (isUA ? theme.fontFamily.primary : theme.fontFamily.secondary)};
	font-size: ${({ isUA }) => (isUA ? '18px' : '16px')};
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 28px;
	padding-top: 20px;
	position: relative;
	text-align: left;
	z-index: 106;

	@media (max-width: 767px) {
		padding-top: 10px;
		margin-bottom: 32px;
	}
	&.second-desc {
		padding-left: 60px;
		@media (max-width: 769px) {
			padding-left: 0;
			margin-bottom: 0;
		}
	}
`;
const StyledDescPhotos = styled.div`
	color: ${({ theme }) => theme.colors.black};
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	font-size: ${({ isUA }) => (isUA ? '18px' : '16px')};
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 28px;
	padding-top: 20px;
	position: relative;
	text-align: left;
	z-index: 106;
	&.white {
		color: ${({ theme }) => theme.colors.white};
		&.photo {
			width: 60%;
			@media (min-width: 768px) and (max-width: 1024px) {
				left: 64px;
				position: absolute;
				bottom: 35px;
			}
			@media (max-width: 767px) {
				left: 24px;
				position: absolute;
				bottom: 20px;
			}
		}
	}
`;

const StyledPhotos = styled.div`
	//height: 100vh;
	align-items: flex-end;
	background-color: ${({ theme }) => theme.colors.black};
	display: flex;
	flex-direction: row;
	padding: 79px 90px 70px 80px;
	position: relative;
	z-index: 105;
	@media (min-width: 768px) and (max-width: 1024px) {
		padding: 64px 64px 50px;
		height: 75vh;
	}
	@media (max-width: 767px) {
		flex-direction: column;
		padding: 24px 25px 24px;
		height: 100%;
	}
`;
const StyledDescPhotoWrapper = styled.div`
	//object-fit: contain;
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;
	.gatsby-image-wrapper {
		width: 100%;
	}

	&.photo-small {
		height: 50%;
		margin-bottom: 0;
		@media (max-width: 767px) {
			height: 100%;
		}
		.cover {
			left: 60px;
			@media (max-width: 1024px) {
				left: 0;
			}
		}
	}
	.cover {
		background-color: ${({ theme }) => theme.colors.black};
		height: 102%;
		left: 0;
		position: absolute;
		top: 0;
		width: calc(100% - 59px);
		z-index: 9;
		&.small {
			left: auto;
			right: 0;
			@media (max-width: 767px) {
				top: -20px;
			}
		}
		@media (max-width: 1024px) {
			width: 100%;
		}
	}
	.img {
		bottom: 0;
		height: 100%;
		left: 0;
		width: calc(100% - 60px);
		z-index: 6;

		&.small {
			left: 60px;
			right: auto;
			width: 100%;
			@media (max-width: 768px) {
				left: 0;
			}
			img {
				object-fit: cover !important;
			}
		}
		@media (min-width: 768px) and (max-width: 1024px) {
			width: calc(100% - 15px);
			&.small {
				width: 100%;
			}
		}
		@media (max-width: 767px) {
			width: 100%;
		}
	}
`;
const StyledPhoto = styled.div`
	align-items: flex-end;
	background-color: ${({ theme }) => theme.colors.black};
	display: flex;
	flex-direction: column;
	height: 100%;
	object-fit: contain;
	overflow: hidden;
	padding: 79px 90px 90px 80px;
	position: relative;
	width: 100%;
	z-index: 105;

	@media (min-width: 768px) and (max-width: 1024px) {
		height: 85vh;
		padding: 0 64px 70px;
		align-items: center;
	}
	@media (max-width: 767px) {
		height: 70vh;
		padding: 0 25px 60px;
		align-items: center;
	}
`;
const StyledExponent = styled.div`
	display: flex;
	font-size: 92px;
	height: 50%;
	left: 102%;
	line-height: 260px;
	position: absolute;
	top: 50%;
	transform: translate(-102%, -50%);
	vertical-align: super;
	@media (min-width: 768px) and (max-width: 1366px) {
		font-size: 84px;
		line-height: 160px;
		left: 503px;
	}

	@media (max-width: 767px) {
		font-size: 62px;
		line-height: 130px;
		left: 330px;
	}
`;
const StyledImgs = styled.div`
	.img1 {
		left: 150px;
		position: absolute;
		top: 180px;
		@media (min-width: 768px) and (max-width: 1024px) {
			top: 154px;
			left: 20px;
		}
		@media (max-width: 767px) {
			left: 0;
			top: 110px;
		}
		img {
			height: 35.25vh;
			width: 27vw;
			@media (min-width: 768px) and (max-width: 1024px) {
				width: 39.656vw;
				height: 29vh;
			}
			@media (max-width: 767px) {
				width: 47.333vw;
				height: 18.53vh;
			}
		}
	}
	.img2 {
		position: absolute;
		right: 78px;

		top: 200px;
		@media (min-width: 768px) and (max-width: 1024px) {
			top: 154px;
			right: 52px;
		}
		@media (max-width: 767px) {
			right: 0;
			top: 150px;
		}
		img {
			height: 25vw;
			width: 17vw;
			@media (min-width: 768px) and (max-width: 1024px) {
				width: 23.87vw;
				height: 34.9vh;
			}
			@media (max-width: 767px) {
				width: 33.333vw;
				height: 100%;
			}
		}
	}
	.img3 {
		bottom: 120px;
		left: 125px;
		position: absolute;

		@media (min-width: 768px) and (max-width: 1024px) {
			bottom: 120px;
			left: 64px;
		}
		@media (max-width: 767px) {
			left: 0;
			bottom: 18vh;
		}
		img {
			height: 24vh;
			width: 22.493vw;
			@media (min-width: 768px) and (max-width: 1024px) {
				width: 35.443vw;
				height: 21.125vh;
			}
			@media (max-width: 767px) {
				width: 48.867vw;
				height: 18.61vh;
			}
		}
	}
	.img4 {
		bottom: 0;
		position: absolute;
		right: 30px;

		@media (min-width: 768px) and (max-width: 1024px) {
			right: 0;
		}
		@media (max-width: 767px) {
			right: 0;
			bottom: 15px;
		}
		img {
			height: 29vh;
			width: 13.493vw;
			@media (min-width: 768px) and (max-width: 1024px) {
				width: 21.443vw;
				height: 26.3125vh;
			}
			@media (max-width: 767px) {
				width: 37.067vw;
				height: 17.98vh;
			}
		}
	}
`;
const StyledHeader = styled.div`
	color: ${({ theme }) => theme.colors.black};
	display: flex;
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 150px;
	font-weight: ${({ theme, isUA }) => (isUA ? theme.fontWeight.medium : theme.fontWeight.light)};
	left: 50%;
	line-height: 155px;
	max-width: 700px;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -60%);
	z-index: 11;
	@media (min-width: 768px) and (max-width: 1366px) {
		width: 500px;
		font-size: 100px;
		line-height: 105px;
	}

	@media (max-width: 767px) {
		font-size: 70px;
		line-height: 95px;
		width: 300px;
	}
`;
const StyledIndicator = styled.div`
	bottom: 10vh;
	left: 50%;
	position: absolute;
	transform: translate(-50%);

	@media (max-width: 1024px) {
		bottom: 0;
		height: 100px;
		left: 50%;
		position: absolute;
		transform: translate(-50%);
		width: 100px;
	}
`;
const StyledInnerWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	width: 100%;
	@media (max-width: 767px) {
		height: 80%;

		&.wrapper-small {
			height: 40%;
			width: 100%;
		}
	}
`;

const PhotosModule = ({ data, pageContext, allLifestyleImages, imgResize, activeLang }) => {
	const { text, description, onePhotoDesc, mainPhotoDesc, descSecond, descThird, exponentText } = data;
	const global = pageContext?.globals?.[0] || [];
	const textIndicator = global?.textIndicator || '';
	const [windowWidth, setWindowWidth] = useState(0);
	const isUA = activeLang === 'ua';

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const width = window?.innerWidth || 0;
			setWindowWidth(width);
		}
	});
	if (typeof window !== 'undefined') {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
	return (
		<>
			<StyledImgs className="section anim">
				<MouseParallaxContainer
					resetOnLeave
					containerStyles={{
						display: 'flex',
						height: windowWidth > 1024 ? 'calc(var(--vh, 1vh) * 110)' : 'calc(var(--vh, 1vh) * 100)',
						backgroundColor: 'rgb(247,245,242)',
						position: 'relative',
						textAlign: 'center',
					}}
				>
					<MouseParallaxChild factorX={0.02} factorY={0.03} className="img1">
						<GatsbyImage
							alt="lifestyle-header"
							className="img"
							image={getImage(allLifestyleImages.find((item) => item.node.name === '6').node)}
						/>
					</MouseParallaxChild>
					<MouseParallaxChild factorX={0.02} factorY={0.03} className="img2">
						<GatsbyImage
							alt="lifestyle-header"
							className="img"
							image={getImage(allLifestyleImages.find((item) => item.node.name === '9').node)}
						/>
					</MouseParallaxChild>
					<MouseParallaxChild factorX={0.03} factorY={0.02} className="img3">
						<GatsbyImage
							alt="lifestyle-header"
							className="img"
							image={getImage(allLifestyleImages.find((item) => item.node.name === '14').node)}
						/>
					</MouseParallaxChild>
					<MouseParallaxChild factorX={0.03} factorY={0.02} className="img4">
						<GatsbyImage
							alt="lifestyle-header"
							className="img"
							image={getImage(allLifestyleImages.find((item) => item.node.name === '29').node)}
						/>
					</MouseParallaxChild>
					<StyledHeader isUA={isUA}>
						{text}
						<StyledExponent> {exponentText} </StyledExponent>
					</StyledHeader>

					<StyledIndicator id="indicatorBtn">
						<ScrollIndicator text={textIndicator} fixed={false} pageContext={pageContext} />
					</StyledIndicator>
				</MouseParallaxContainer>
			</StyledImgs>
			<StyledDesc className="section" id="scrollTo">
				<StyledText
					data-animation-type="text-third"
					className="anim text-third"
					isUA={isUA}
					dangerouslySetInnerHTML={GetHtmlFromRedactorField(description)}
				/>
			</StyledDesc>
			<StyledMainPhoto className="section">
				<StyledImgWrapperMain className="anim" data-animation-type="one-image">
					<GatsbyImage
						alt="mainInvestment"
						className="img-desc"
						image={getImage(allLifestyleImages.find((item) => item.node.name === '26').node)}
					/>
					<div className="reveal" />
				</StyledImgWrapperMain>
				<StyledDescPhotos isUA={isUA}> {mainPhotoDesc} </StyledDescPhotos>
			</StyledMainPhoto>
			<StyledPhotos className="section anim" data-animation-type="photos">
				<StyledInnerWrapper>
					<StyledDescPhotoWrapper>
						<div className="cover" />
						<GatsbyImage alt="lifestyle" className="img check" image={getImage(imgResize[0].node)} />
					</StyledDescPhotoWrapper>
					<StyledDescPhoto isUA={isUA}>{descSecond}</StyledDescPhoto>
				</StyledInnerWrapper>
				<StyledInnerWrapper className="wrapper-small">
					<StyledDescPhotoWrapper className="photo-small">
						<div className="cover" />
						<GatsbyImage
							alt="lifestyle"
							className="img check small"
							objectFit="cover"
							image={getImage(allLifestyleImages.find((item) => item.node.name === '5').node)}
						/>
					</StyledDescPhotoWrapper>
					<StyledDescPhoto className="second-desc" isUA={isUA}>
						{descThird}
					</StyledDescPhoto>
				</StyledInnerWrapper>
			</StyledPhotos>
			<StyledPhoto className="section">
				<StyledImgWrapper className="anim" data-animation-type="one-image-right">
					<GatsbyImage
						alt="lifestyle"
						className="img"
						image={getImage(allLifestyleImages.find((item) => item.node.name === '24').node)}
					/>
					<div className="reveal-right" />
				</StyledImgWrapper>
				<StyledDescPhotos className="white photo" isUA={isUA}>
					{' '}
					{onePhotoDesc}{' '}
				</StyledDescPhotos>
			</StyledPhoto>
		</>
	);
};

export default PhotosModule;
