import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useDispatch } from 'react-redux';
import TextModule from '../components/lifestyle/textModule';
import Numbers from '../components/lifestyle/numbers';
import myImage from '../assets/image/360/klubowa.webp';
import myImageMobile from '../assets/image/360/klubowa_mobile.webp';
import Module360 from '../components/360/module360';
import PhotosModule from '../components/lifestyle/photosModule';
import PageContainer from '../common/PageContainer';
import { setScaleIntroAnimation } from '../state/scaleIntroAnim';
import { setIntroStartModule } from '../state/introStartModule';
import { setIntroLoadedOnce } from '../state/introLoadedOnce';
import { setMenuColor } from '../state/menuColor';
import { setIsDropdownMenu } from '../state/isDropdownMenuSlice';
import Seo from '../components/seo/Seo';

const LifestylePage = ({ data: { lifestyleData, restOfImages, image }, pageContext }) => {
	const activeLang = (pageContext?.locale === 'uk' ? 'ua' : pageContext?.locale) || '';
	const pageData = lifestyleData?.nodes?.[0] || '';
	const seoGlobal = pageContext?.seoGlobal?.data?.datoCmsSite?.globalSeo || [];
	const seoData = pageData?.seoSettings || '';
	const allLifestyleImages = restOfImages?.edges;
	const imgResize = image?.edges;

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setScaleIntroAnimation(true));
		dispatch(setIntroStartModule(false));
		dispatch(setMenuColor(true));
		dispatch(setIntroLoadedOnce(true));
		if (typeof window !== 'undefined') {
			const width = window?.innerWidth || 0;
			if (width < 1024) {
				dispatch(setIsDropdownMenu(true));
			}
		}
	}, []);
	return (
		<>
			<Seo seoGlobal={seoGlobal} seoData={seoData} />
			<PageContainer className="full-width">
				<PhotosModule
					data={pageData}
					pageContext={pageContext}
					allLifestyleImages={allLifestyleImages}
					imgResize={imgResize}
					activeLang={activeLang}
				/>
				<Module360 desktopImgSrc={myImage} mobileImgSrc={myImageMobile} />
				<Numbers data={pageData} activeLang={activeLang} />
				<div className="last">
					<TextModule data={pageData} activeLang={activeLang} activeLang={activeLang} />
				</div>
			</PageContainer>
		</>
	);
};

export default LifestylePage;
export const query = graphql`
	query ($locale: String) {
		lifestyleData: allDatoCmsLifestylePage(filter: { locale: { eq: $locale } }) {
			nodes {
				seoSettings {
					title
					description
					twitterCard
					image {
						url
					}
				}
				textLink
				textDescription
				textLinkSlug {
					... on DatoCmsHomePage {
						slug
						originalId
					}
					... on DatoCmsApartmentsPage {
						slug
						originalId
					}
					... on DatoCmsContactPage {
						slug
						originalId
					}
					... on DatoCmsFoodstoriesPage {
						slug
						originalId
					}
					... on DatoCmsInvestmentPage {
						slug
						originalId
					}
					... on DatoCmsLifestylePage {
						slug
						originalId
					}
					... on DatoCmsNohoSmartPage {
						slug
						originalId
					}
				}
				numberValueAndDescription {
					numberValue
					numberDescription
				}
				descSecond
				descThird
				description
				mainPhotoDesc
				exponentText
				text
				onePhotoDesc
			}
		}
		restOfImages: allFile(filter: { sourceInstanceName: { eq: "lifestyleGallery" }, name: { ne: "28" } }) {
			edges {
				node {
					id
					name
					childImageSharp {
						gatsbyImageData
					}
				}
			}
		}
		image: allFile(filter: { sourceInstanceName: { eq: "lifestyleGallery" }, name: { eq: "28" } }) {
			edges {
				node {
					id
					name
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
			}
		}
	}
`;
