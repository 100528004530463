import React from 'react';
import styled from 'styled-components';
import PageContainer from '../../common/PageContainer';

const StyledView = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.white};
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	margin-bottom: 25px;
	min-height: 100vh;
	text-align: center;
	width: 100vw;
`;
const StyledGrid = styled.div`
	display: grid;
	font-family: ${({ theme }) => theme.fontFamily.primary};
	height: fit-content;
	opacity: 0;
	position: relative;
	top: 90px;
	width: 100%;

	h1 {
		display: flex;

		font-weight: ${({ theme }) => theme.fontWeight.regular};
		p {
			//font-size: 16px;
			font-family: ${({ theme }) => theme.fontFamily.secondary};
			font-size: ${({ isUA }) => (isUA ? '18px' : '16px')};
			max-width: 170px;
		}
	}
	@media (max-width: 765px) {
		top: 20px;
		font-size: 50px;
		grid-template-columns: 1fr;

		h1 {
			flex-wrap: wrap;
			&:nth-of-type(even) {
				justify-content: right;
				span,
				p {
					text-align: right;
				}
			}
			span {
				width: 100%;
				text-align: left;
			}
			p {
				text-align: left;
			}
		}
	}
	@media (min-width: 766px) {
		font-size: 70px;
		top: 15px;
		margin-top: 83px;
		grid-template-rows: auto;
		h1 {
			&:nth-of-type(even) {
				justify-self: end;
			}
			&:nth-of-type(2) {
				p {
					text-align: left;
				}
			}
			&:not(:last-of-type):nth-of-type(odd) {
				p {
					margin-top: 30px;
				}
			}
			p {
				text-align: left;
				margin-top: 30px;
				margin-left: 10px;
			}
		}
	}
	@media (min-width: 1366px) {
		font-size: 80px;
		margin-top: 148px;
		top: 38px;
		grid-template-rows: auto;
		grid-template-columns: repeat(3, 1fr);
		grid-template-areas:
			'desktop-first desktop-first desktop-first'
			'desktop-last-first desktop-last-second desktop-last-second';

		h1 {
			text-align: left;
			justify-self: center;
			&:nth-of-type(even) {
				justify-self: center;
			}
			&.desktop-first {
				grid-area: desktop-first;
				margin-left: -14%;
			}
			&.desktop-last-first {
				grid-area: desktop-last-first;
				margin-left: 150px;
				margin-bottom: 30px;
			}
			&.desktop-last-second {
				grid-area: desktop-last-second;
				margin-bottom: 30px;
			}
			p {
				text-align: left;
			}
		}
	}
	@media (max-width: 420px) {
		top: 20px;
	}
`;

const Numbers = ({ data, activeLang }) => {
	const isUA = activeLang === 'ua';
	const { numberValueAndDescription } = data;
	return (
		<StyledView className="section">
			<PageContainer className="content-standard">
				<StyledGrid className="anim numbers" data-animation-type="numbers" isUA={isUA}>
					{numberValueAndDescription.map((item, index) => (
						<h1
							key={index}
							className={(index === 3 && 'desktop-last-first') || (index === 4 && 'desktop-last-second') || 'numbers'}
						>
							<span>{item.numberValue}</span>
							<p>{item.numberDescription}</p>
						</h1>
					))}
				</StyledGrid>
			</PageContainer>
		</StyledView>
	);
};

export default Numbers;
